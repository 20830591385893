<template>
  <div class="text-center">
    <v-dialog
      :model-value="showAddActivityFromContent"
      maxWidth="400"
      :persistent="true"
      @click:outside="doCancel"
    >
      <v-card>
        <div class="px-6 pt-5">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            style="border-radius: 50%"
            class="absolute right-4 top-4 rounded-full bg-[#F93232] md:p-2 p-1.5 leading-normal hover:bg-[#F93232]/80 focus:bg-[#F93232]/60 focus:outline-none focus:ring-0 active:bg-[#F93232]/70"
            @click.prevent="doCancel"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="md:w-5 md:h-5 w-4 h-4"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#fff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M18 6l-12 12"/>
              <path d="M6 6l12 12"/>
            </svg>
          </button>
          <p class="md:text-base text-xs font-bold">{{ $t("LB_CREATE_ACTIVITY_FOR") }} ...</p>
        </div>

        <hr class="mt-6 mb-0 h-px border-0 bg-gray-200/90" >

        <div class="px-6">
          <template v-if="!isLoading">
            <div v-for="(classOrGroup, index) of classesAndGroups" :key="index">
              <div class="flex items-center justify-between py-3">
                <div class="flex items-center space-x-8">
                  <div
                    class="flex items-center gap-2"
                    style="cursor: pointer;"
                    @click="addToClassOrGroup(classOrGroup)"
                  >
                    <img
                      src="/images/group-blue.svg"
                      alt=""
                      class="w-5 h-5"
                      v-if="classOrGroup.classGroupId"
                    >
                    <img
                      src="/images/correspondents.svg"
                      alt=""
                      class="w-5 h-5"
                      v-else
                    >
                    <p class="md:text-sm text-xs font-bold mb-0">
                      {{ classOrGroup.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="isLoading"
            style="height: 400px; overflow: hidden; overflow-y: auto"
          >
            <p class="w-100 p-3 text-center">
              {{ $t("LB_LOADING") }}
            </p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import {useMainStore} from "~/store";

const localePath = useLocalePath()

const isLoading = ref(true);
const classesAndGroups = ref([]);

const mainStore = useMainStore()
const {showAddActivityFromContent} = storeToRefs(mainStore)

watch(showAddActivityFromContent, async () => {
  if (showAddActivityFromContent.value) {
    isLoading.value = true;
    classesAndGroups.value = [];
    const classGroups = await useMyOFetch('/groups/teacher-workspace');
    for (const classGroup of classGroups) {
      classesAndGroups.value.push({classGroupId: classGroup.id, name: classGroup.name});
      classesAndGroups.value.push(...classGroup.correspondentClasses.map(c => {return {correspondentClassId: c.id, name: c.name}}));

    }
    isLoading.value = false;
  }
})

const addToClassOrGroup = (classOrGroup) => {
  const contentId = useNuxtApp().$store.addActivityFromContentData;
  useNuxtApp().$store.setShowAddActivityFromContent({ value: false, data: null });

  navigateTo({
    path: localePath("/teacher/edit-activity"),
    query: {
      classGroupId: classOrGroup.classGroupId,
      correspondentClassId: classOrGroup.correspondentClassId,
      className: classOrGroup.name,
      contentId: contentId
    },
  });
};

const doCancel = () => {
  useNuxtApp().$store.setShowAddActivityFromContent({ value: false, data: null });
};
</script>
