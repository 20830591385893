<template>
  <v-app>
    <div
      :id="pageId"
      class="grey-page !px-0"
    >
      <PrivateHeader :key="'private_header_' + $store.userImageCounter" />
      <slot />
    </div>
    <ModalCall />
    <ModalSharePost />
    <ModalAddActivityFromContent />
    <ExternalLinkDialog />
    <ConfirmationDialog />
  </v-app>
</template>

<script setup>
import ExternalLinkDialog from "~/components/ExternalLinkDialog.vue";

const route = useRoute();
watch(() => route.name, async () => {
  if (!useNuxtApp().$chat.isConnected()) {
    await useNuxtApp().$chat.tryToReconnect();
  }
})

const pageId = computed(() => {
  const pathWithoutId = route.path
    .replace(/^\/..$/, "") // Removes the first part of the path if it's 2 letters (locale) and there is nothing after (home page)
    .replace(/^\/..\//, "/") // Removes the first part of the path if it's 2 letters (locale)
    .replace(/\/\d+$/, "") // Removes the last segment if it's a number
    .replace(/\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/, ""); // Removes the last segment if it's a uuid
  return "page_" + pathWithoutId.replace(/\//g, "-"); // Replaces '/' with "-"
})

const loadData = async () => {
  try {
    await useNuxtApp().$chat.init();

    setInterval(async () => {
      const isOnline = await checkBrowserConnection();
      if (isOnline) {
        await useNuxtApp().$chat.tryToReconnect();
      } else {
        await useNuxtApp().$chat.close();
      }
    }, 30000);

    window.addEventListener("offline", async (_event) => {
      await useNuxtApp().$chat.close();
    });

    window.addEventListener("online", async (_event) => {
      await useNuxtApp().$chat.tryToReconnect();
    });

  } catch (err) {
    console.log(err);
  }
};
loadData();

const checkBrowserConnection = () => {
  try {
    return navigator.onLine;
  } catch (err) {
    console.log(err);
    return false;
  }
};

addEventListener("online", (_event) => {
  console.log("GOING ONLINE")
});
addEventListener("offline", (_event) => {
  console.log("GOING OFFLINE")
});
</script>

<style scoped>
.grey-page {
  background: #f5f5f5;
  min-height: 100vh;
}
</style>
